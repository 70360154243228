<template>
  <v-container>
    <!-- toolbar -->
    <v-row>
      <v-col cols="12">
        <v-toolbar dense color="primary" dark flat rounded>
          <v-toolbar-title>Browse Travel Accommodations</v-toolbar-title>
          <v-spacer></v-spacer>

          <!-- home/dashboard btn -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                dark
                v-bind="attrs"
                v-on="on"
                @click="$router.push('/dashboard')"
              >
                <v-icon>mdi-home-outline</v-icon>
              </v-btn>
            </template>
            <span>Home</span>
          </v-tooltip>
          <!-- home/dashboard btn -->

          <!-- dark mode button -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                dark
                v-bind="attrs"
                v-on="on"
                @click="$vuetify.theme.dark = !$vuetify.theme.dark"
              >
                <v-icon>{{
                  $vuetify.theme.dark
                    ? "mdi-lightbulb-outline"
                    : "mdi-lightbulb"
                }}</v-icon>
              </v-btn>
            </template>
            <span>Dark Mode</span>
          </v-tooltip>
          <!-- dark mode button -->

          <!-- sign out btn -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                dark
                v-bind="attrs"
                v-on="on"
                @click="onSignOut"
                class="d-none d-sm-inline-flex"
              >
                <v-icon>mdi-logout-variant</v-icon>
              </v-btn>
            </template>
            <span>Sign Out</span>
          </v-tooltip>
          <!-- sign out btn -->

          <!-- notifications button -->
          <!-- <v-menu
            left
            bottom
            offset-y
            transition="slide-y-transition"
            v-if="userAccount.newDashboardMessageNotification"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                depressed
                color="primary"
                v-bind="attrs"
                v-on="on"
                class="d-none d-sm-inline-flex"
              >
                <v-badge color="pink">
                  <v-icon>mdi-bell</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <v-list dense :class="$vuetify.theme.dark ? '' : 'grey lighten-3'">
              <v-list-item
                :class="$vuetify.theme.dark ? '' : 'grey lighten-3'"
                link
                @click="$router.push('/dashboard')"
                v-if="userAccount.newDashboardMessageNotification"
              >
                <v-list-item-title
                  >You have a new message. Click here to review your
                  timeline.</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu> -->
          <!-- notifications button -->
        </v-toolbar>
      </v-col>
    </v-row>

    <!-- Your Travel Details -->
    <v-row>
      <v-col col="12" class="d-md-flex">
        <v-card outlined class="d-md-flex flex-md-column">
          <v-card-title class="headline font-weight-medium d-flex">
            <span class="d-block">Your Travel Details</span>
            <v-spacer />
            <v-btn
              outlined
              color="primary"
              @click="helpDialog = true"
              :loading="loading"
              :disabled="loading"
              small
              class="d-none"
              >I Need Help</v-btn
            >
          </v-card-title>
          <v-card-text class="text--primary">
            <v-row>
              <v-col cols="12" md="3">
                <span class="d-block body-1 font-weight-medium"
                  >Destination</span
                >
                <span class="d-block">{{ specificWinner.destination }}</span>
              </v-col>

              <v-col cols="12" md="3">
                <span class="d-block body-1 font-weight-medium"
                  >Number of Nights</span
                >
                <span class="d-block">{{
                  specificWinner.numberNights
                }}</span></v-col
              >

              <v-col cols="12" md="3">
                <span class="d-block body-1 font-weight-medium">Room Type</span>
                <span class="d-block">{{
                  specificWinner.unitType
                }}</span></v-col
              >

              <v-col cols="12" md="3">
                <span class="d-block body-1 font-weight-medium"
                  >Travel By Date</span
                >
                <span class="d-block">{{
                  formatDate(specificWinner.travelByDate)
                }}</span></v-col
              >
            </v-row>

            <v-row class="mt-6 d-none">
              <!-- travel within next 90 days -->
              <v-col cols="12" md="4" class="d-md-flex">
                <v-alert
                  style="cursor: pointer; width: 100%"
                  color="error"
                  dark
                  @click="
                    {
                    }
                  "
                >
                  Click here if you're looking for travel within the next 90
                  days
                </v-alert></v-col
              >
              <!-- travel within next 90 days -->
              <!-- travel to alternative destination -->
              <v-col cols="12" md="4" class="d-md-flex">
                <v-alert
                  style="cursor: pointer; width: 100%"
                  color="primary"
                  dark
                  @click="
                    {
                    }
                  "
                >
                  Click here if you'd like to travel to a destination other than
                  {{ winnerRetailPackage.destination }}
                </v-alert></v-col
              >
              <!-- travel to alternative destination -->
              <!-- travel within US -->
              <v-col cols="12" md="4" class="d-md-flex">
                <v-alert
                  style="cursor: pointer; width: 100%"
                  color="primary"
                  dark
                >
                  Click here if you'd like to travel to a destination within the
                  United States
                </v-alert></v-col
              >
              <!-- travel within US -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- alternative destinations -->
    <v-row
      v-if="
        viewDestinationOnlyThesePackages
      "
    >
      <v-col cols="12">
        <v-card outlined>
          <v-card-text class="text--primary">
            <v-row>
              <v-col cols="12" md="6">
                <h3 class="headline font-weight-medium mt-2">
                  Alternative Travel Destinations
                </h3>
                <p class="mt-1 body-2">
                  Looking to save on airfare or travel soon? Book one of our
                  alternative destinations.
                </p>
              </v-col>
              <!-- class="d-flex align-center justify-end" -->
              <v-col
                cols="12"
                md="6"
                :align="$vuetify.breakpoint.mdAndUp ? 'end' : ''"
              >
                <v-btn
                  @click="$vuetify.goTo('#optionsTable')"
                  color="primary"
                  small
                  depressed
                  :block="$vuetify.breakpoint.xs ? true : false"
                  >View Original Package Accommodations</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="3"
                v-for="(item, index) in alternativeDestinations"
                :key="index"
              >
                <v-card outlined>
                  <v-img
                    style="cursor: pointer"
                    height="125"
                    :src="item.image"
                    @click="
                      $router.push(`/alternative-destinations/${item.id}`)
                    "
                  />
                  <v-card-text
                    class="text--primary text-subtitle-1 font-weight-medium pb-0"
                  >
                    <span class="d-block">{{ item.name }}</span>
                    <span class="d-block caption mb-2"
                      >{{ item.nights }} Nights, 1-{{
                        item.guests
                      }}
                      Guests</span
                    >
                  </v-card-text>
                  <v-card-actions class="pl-4">
                    <v-btn
                      depressed
                      color="primary"
                      xSmall
                      @click="
                        $router.push(`/alternative-destinations/${item.id}`)
                      "
                      >Book Now</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- options table -->
    <v-row id="optionsTable">
      <v-col cols="12">
        <v-card outlined :loading="loading">
          <v-card-text class="my-3 text--primary d-flex items-center">
            <v-row>
              <v-col cols="12" md="8">
                <h3 class="headline font-weight-medium">
                  Travel Accommodations for {{ specificWinner.destination }}
                </h3></v-col
              >
              <v-col cols="12" md="4" align="end" align-self="center">
                <p class="mb-0 body-2">
                  {{
                    filteredInventoryListings
                      ? filteredInventoryListings.length
                      : ""
                  }}
                  Options Available
                </p>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="text--primary">
            <v-data-table
              dense
              :items="filteredInventoryListings"
              :items-per-page="-1"
              :headers="headers"
              fixed-header
              :height="500"
              @click:row="onViewAccommodation"
            >
              <template v-slot:[`item.moreInfo`]="{ item }">
                <v-btn small text color="primary">Book/More Info</v-btn>
                {{ item.moreInfo }}
              </template>
              <template v-slot:[`item.checkInDate`]="{ item }">
                {{ formatDate(item.checkInDate) }}
              </template>
              <template v-slot:[`item.checkOutDate`]="{ item }">
                {{ formatDate(item.checkOutDate) }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- help dialog -->
    <v-dialog v-model="helpDialog" width="500">
      <v-card outlined :loading="loading">
        <v-btn
          color="error"
          outlined
          absolute
          top
          right
          :loading="loading"
          :disabled="loading"
          icon
          @click="helpDialog = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <v-card-text class="text--primary headline font-weight-medium mt-6">
          Please click on the video for a detailed explanation of how to select
          travel accommodations</v-card-text
        >
        <v-card-text class="body-1 text--primary mt-3 mb-0 pb-2">
          <p>
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/hilYom8X7ew"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </p>

          <p>Thank you for booking travel accommodations.</p>

          <p class="pt-2">
            If you've watched the video, but still have questions. Please click
            the button below to contact us.
          </p>
        </v-card-text>
        <v-card-actions class="flex-column">
          <v-btn
            block
            color="primary"
            depressed
            class="mx-0"
            @click="
              helpDialog = false;
              messageDialog = true;
            "
            >I've watched the video, but still have questions</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- message dialog -->
    <v-dialog v-model="messageDialog" width="500">
      <v-card outlined :loading="loading">
        <v-card-title class="headline font-weight-medium my-3">
          Contact Us
        </v-card-title>
        <v-card-text class="text--primary body-1">
          <p>
            Please enter your message below. We'll get back to you within 3
            business days (usually sooner).
          </p>
          <p>
            <strong
              >You'll receive an email notication when we've replied.</strong
            >
          </p>
          <p>Thank you!</p>
          <v-form ref="messageForm" @submit.prevent="onSendMessage">
            <v-textarea
              v-model.trim="message.message"
              :loading="loading"
              :disabled="loading"
              label="Message *"
              :rules="[textRules.required]"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            depressed
            @click="messageDialog = false"
            :loading="loading"
            :disabled="loading"
            >Cancel</v-btn
          >

          <v-spacer />
          <v-btn
            color="primary"
            depressed
            @click="onSendMessage"
            :disabled="loading"
            :loading="loading"
          >
            Contact Us</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- import inventory info dialog -->
    <v-dialog v-model="importantInfoDialog" width="600" persistent>
      <v-card :loading="loading">
        <v-card-text class="pt-6 text--primary">
          <h2 class="headline mb-5">
            <v-icon left color="error" class="mb-1">mdi-alert-outline</v-icon>
            Important Inventory Information
          </h2>
          <div class="body-1">
            <p>
              Inventory is updated regularly.
              <strong
                >The most up to date inventory listings available for your
                package are currently displayed.</strong
              >
            </p>
            <p>
              Please note that
              <strong
                >inventory listings greater than 180 days from today may not yet
                be available,</strong
              >
              as we have yet to receive them from our suppliers.
            </p>
            <p>
              If you do not see the dates you would like to travel on, please
              return to your travel portal regularly to view the most up to date
              inventory listings.
            </p>
            <p>Thank you</p>
            <!-- maui/kauai info -->
            <v-alert
              type="info"
              v-if="showMauiInfoInImportantInfoDialog"
              :icon="false"
              class="mt-6"
            >
              <div>
                <h3 class="mb-3">Notice for MAUI package holders</h3>
                <p>
                  We have received reports from package winners who have
                  recently or are currently visiting Maui, HI letting us know
                  that Maui is currently experiencing closed restaurants and
                  other venues, slow service, cancelled tours and activities as
                  well other inconveniences due to short-staffing and other
                  Covid-19 related issues.
                </p>
                <p>
                  As such,
                  <strong
                    ><u
                      >we do not recommend traveling to Maui in the near
                      future.</u
                    ></strong
                  >
                  We have substituted our Maui inventory with destinations on
                  the Isle of Kauai. For travelers holding packages that offer
                  Maui as the only destination and that do not wish to vacation
                  on the isle of Kauai, we recommend looking into your options
                  in the alternative destinations that are displayed on the
                  inventory selection page. For those travelers holding
                  multi-destination packages that do not wish to vacation on the
                  isle of Kauai, we recommend reviewing the other exciting
                  destinations offered by your package as well as the
                  alternative destination options.
                </p>
                <p class="mb-1">Thank you</p>
              </div>
            </v-alert>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            @click="showMauiInfoInImportantInfoDialog = false"
            :loading="loading"
            :disabled="loading"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 2D200, 4D100, 4D104, 4D200, 4D204, SAV236, SAV136 -->
    
    <!-- <div v-if="packageDialogComputed"> -->
      <v-dialog v-model="packageDialog" width="600"  class="packageCode">
        <v-card :loading="loading">
          <v-card-text class="pt-6 text--primary body-1">
            <h2 class="headline mb-5 font-weight-bold">Alert!</h2>

            <p>
              Due to the recent tragedy of the Lahaina Wildfires in Maui,
              Hawaii, ALL Maui inventory has been removed from our system until
              further notice. Availability on Kauai, Oahu, and Big Island are
              still available, however, availability is extremely limited as
              resorts on the other islands may be used to shelter displaced
              residents of Maui. We strongly discourage travel to Hawaii at this
              time to allow Maui to begin the recovery process.
            </p>
            <p>
              If you would like to help the residents of Maui in their time of
              need, donations can be made here:
              <a
                target="_blank"
                href="https://www.hawaiicommunityfoundation.org/maui-strong"
                >Maui Strong Fund</a
              >
            </p>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              depressed
              @click="closePackageDialog"
              :loading="loading"
              :disabled="loading"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- </div> -->

    <!-- <PackageDialog :dialoge="packageDialogComputed" /> -->
    

    <!-- 4D100, 4D200, SAV103, SAV104, 3D100, AI4IC, SAV118 -->
    <div v-if="mexicoPackageDialog">
      <v-dialog v-model="mexicoPackageDialog" width="600" persistent class="packageCode">
        <v-card :loading="loading">
          <v-card-text class="pt-6 text--primary body-1">
            <h2 class="headline mb-5 font-weight-bold">Alert!</h2>

            <p>
              Violent crime – such as homicide, kidnapping, carjacking, and robbery – is widespread and common in Mexico. The U.S. government has limited ability to provide emergency services to U.S. citizens in many areas of Mexico, as travel by U.S. government employees to certain areas is prohibited or restricted. In many states, local emergency services are limited outside the state capital or major cities.
            </p>
            <p>
              There are currently level 3 Reconsider Travel Advisories for Baja California (Los Cabos) and Jalisco (Puerto/Nuevo Vallarta) due to increased crime and kidnapping.
              
            </p>
            <p>There is currently a level 2 Exercise Increased Caution Travel Advisory for Quintana Roo (Cancun).</p>
            <p>As such, we strongly recommend against travel to these areas in Mexico at this time.</p>
            <p>You may review the Mexico Travel Advisory here: 
              <a
                target="_blank"
                href="https://travel.state.gov/content/travel/en/traveladvisories/traveladvisories/mexico-travel-advisory.html"
                >https://travel.state.gov/content/travel/en/traveladvisories/traveladvisories/mexico-travel-advisory.html</a
              >
            </p>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              depressed
              @click="mexicoPackageDialog = false"
              :loading="loading"
              :disabled="loading"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- sav103Dialog -->
    <v-dialog v-model="sav103Dialog" width="600" persistent>
      <v-card :loading="loading">
        <v-card-text class="pt-6 text--primary body-1">
          <h2 class="headline mb-5 font-weight-bold">
            Your Options Have Been Expanded!
          </h2>

          <p>
            We have enhanced your package with the availability of
            <strong>an additional destination in Mexico:</strong>
          </p>
          <ul class="font-weight-medium">
            <li>Cancun, Mexico</li>
          </ul>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            @click="sav103Dialog = false"
            :loading="loading"
            :disabled="loading"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- sav104Dialog -->
    <v-dialog v-model="sav104Dialog" width="600" persistent>
      <v-card :loading="loading">
        <v-card-text class="pt-6 text--primary body-1">
          <h2 class="headline mb-5 font-weight-bold">
            Your Options Have Been Expanded!
          </h2>

          <p>
            We have enhanced your package with the availability of
            <strong>two additional destinations in Mexico:</strong>
          </p>
          <ul class="font-weight-medium">
            <li>Nuevo Vallarta, Mexico</li>
            <li>Cancun, Mexico</li>
          </ul>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            @click="sav104Dialog = false"
            :loading="loading"
            :disabled="loading"
            ref="closeBtn104"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import onSignOut from "@/mixins/onSignOut";
import firebase from "@/plugins/firebase";
import formatDate from "@/mixins/formatDate";
import formRules from "@/mixins/formRules";
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
export default {
  mixins: [onSignOut, formatDate, formRules],
  firestore: {
    alternativeDestinations: firebase
      .firestore()
      .collection("alternativeDestinations"),

    destinationViewOnlyPackage: firebase
      .firestore()
      .collection("destinationViewOnlyThesePackages")
      .doc("Rvopq36MgxI8BJ153qNF"),
  },

  data: () => ({
    destinationViewOnlyPackage: {},
    alternativeDestinations: [],
    packageDialog: false,
    mexicoPackageDialog:false,
    sav103Dialog: false,
    sav104Dialog: false,
    thirdYearSurcharge: false,
    // displayAlternativeDestinations: false,
    importantInfoDialog: false,
    showMauiInfoInImportantInfoDialog: false,
    items: [],
    userAccount: "",
    eventReport: "",
    specificWinner: "",
    winnerRetailPackage: "",
    packageCode:'',
    helpDialog: false,
    messageDialog: false,
    message: {
      adminActionRequired: true,
      archived: false,
      dateCreated: new Date().toISOString().substring(0, 10),
      from: "",
      message: "",
      readByAdmin: false,
      repliedToByAdmin: false,
      senderActionRequired: false,
      title: "You sent us a message",
      to: "admin",
      icon: "mdi-message-text-outline",
      color: "primary",
    },
    inventoryListings: [],
    unitSizeHotels:["CHAH", 
"CPMD", 
"DSBA", 
"ECGV", 
"ERMB", 
"GHGL", 
"HBSV", 
"HCAH", 
"HCEI", 
"HCEM", 
"HCGF", 
"HCGP", 
"HCGT", 
"HCHE", 
"HCHE2",
"HCHH", 
"HCHM", 
"HCHP", 
"HCHT", 
"HCIO", 
"HCLE", 
"HCMG", 
"HCMT", 
"HCMT2",
"HCNH", 
"HCOS", 
"HCPL", 
"HCPM", 
"HCRH", 
"HCRH2",
"HCRN", 
"HCRP", 
"HCRP2",
"HCVM", 
"HGOH", 
"HHHN", 
"HMGM", 
"HMLV", 
"HMMH", 
"HPLV", 
"HSMD", 
"LHPL", 
"MBLV", 
"SPGH", 
"TAHS", 
"TJOE", 
"TWCC", 
"AODR", 
"OCAI",
"SPPD",
"BPGJ", 
"RDCB",],
    alternativeDestinations1: [
      {
        id: "phoenix",
        text: "Phoenix, Arizona",
        img: "https://flyeia.com/wp-content/uploads/Phoenix.jpg",
        matchPackage: "Phoenix",
      },
      {
        id: "majesticcolorado",
        text: "Majestic Colorado",
        img: "https://static.wixstatic.com/media/3b49e3_dc8f99e6042647e3a5d923f2e867ba09~mv2.jpg/v1/fill/w_640,h_300,al_c,q_80,usm_0.66_1.00_0.01/3b49e3_dc8f99e6042647e3a5d923f2e867ba09~mv2.webp",
        matchPackage: "Majestic Colorado",
      },
      // 3 items below were combined into "majestic colorado" in Nov 2021
      // {
      //   id: "breckenridge",
      //   text: "Breckenridge, Colorado",
      //   img: "https://static.wixstatic.com/media/3b49e3_dc8f99e6042647e3a5d923f2e867ba09~mv2.jpg/v1/fill/w_640,h_300,al_c,q_80,usm_0.66_1.00_0.01/3b49e3_dc8f99e6042647e3a5d923f2e867ba09~mv2.webp",
      // },
      // {
      //   id: "vailbeavercreek",
      //   text: "Vail/Beaver Creek, Colorado",
      //   img: "https://cdn.theculturetrip.com/wp-content/uploads/2018/10/web_hires_vailresorts_arrabelle_ext3_82581.jpg",
      // },
      // {
      //   id: "avonbeavercreek",
      //   text: "Avon/Beaver Creek, Colorado",
      //   img: "https://i.pinimg.com/originals/fe/98/55/fe9855eea9f52d82bff67de0d0730019.jpg",
      // },
      {
        id: "orlando",
        text: "Orlando, Florida",
        img: "https://cdn.britannica.com/07/201607-050-0B5774CB/Orlando-Florida-aerial-cityscape-towards-Eola-Lake.jpg",
        matchPackage: "Orlando",
      },
      {
        id: "branson",
        text: "Branson, Missouri",
        img: "https://d194ip2226q57d.cloudfront.net/images/BransonLanding-Night_CO-Branson-Landing.original.jpg",
        matchPackage: "Branson",
      },
      {
        id: "parkcity",
        text: "Park City, Utah",
        img: "https://i0.wp.com/popoversandpassports.com/wp-content/uploads/2021/03/img_6964.jpg?resize=1024%2C768&ssl=1",
        matchPackage: "Park City",
      },
      {
        id: "lasvegas",
        text: "Las Vegas, Nevada",
        img: "https://cdn.travelpulse.com/images/66aaedf4-a957-df11-b491-006073e71405/fcf25948-ddaf-4d40-8f2a-93b5d1a7e134/630x355.jpg",
        matchPackage: "Las Vegas",
      },
      {
        id: "williamsburg",
        text: "Williamsburg, Virginia",
        img: "https://www.ideal-living.com/wp-content/uploads/2015/03/Williamsburg_Estate.jpg",
        matchPackage: "Williamsburg",
      },
      {
        id: "cancun",
        text: "Cancun, Mexico",
        img: "https://expertvagabond.com/wp-content/uploads/cancun-things-to-do-guide.jpg",
        matchPackage: "Cancun",
      },
      {
        id: "loscabos",
        text: "Los Cabos, Mexico",
        img: "https://cdn.thecrazytourist.com/wp-content/uploads/2018/07/ccimage-shutterstock_162855161.jpg",
        matchPackage: "Los Cabos",
      },
      {
        id: "puertovallarta",
        text: "Puerto Vallarta/Nuevo, Mexico",
        img: "https://www.tripsavvy.com/thmb/igu9AHgwX-cRsn9pGZPeNR_Ssx4=/1885x1414/smart/filters:no_upscale()/playa-los-muertos-pier-and-beach--puerto-vallarta--comes-alive-with-activity-by-locals-and-tourists-1062964602-1f152cee77854b088600fd043462a939.jpg",
        matchPackage: "Puerto Vallarta",
      },
      {
        id: "britishcolumbia",
        text: "British Columbia, Canada",
        img: "https://www.seabourn.com/content/dam/sbn/inventory-assets/ports/YYJ/victoria-bc.jpg.image.750.563.low.jpg",
        matchPackage: "British Columbia",
      },
    ],
  }),
  computed: {
    viewDestinationOnlyThesePackages() {
      if(this.eventReport && this.eventReport.winners){
        let winnerCode = this.eventReport.winners.filter(
        (el) => el.packageID == this.userAccount.packageID
      )[0].packageCode;
      let viewOrNot = this.destinationViewOnlyPackage.packages.some(
        (el) => el == winnerCode
      );
      return viewOrNot;
      }
      return false;
    },
    headers() {
      // get all property IDs for garden unit upgrade surcharge
      let allPropertyIds = this.items.map((i) => i.resortPropertyId);
      if (allPropertyIds.length) {
        allPropertyIds = new Set(allPropertyIds);
        allPropertyIds = Array.from(allPropertyIds);
      }

      if (this.thirdYearSurcharge) {
        let items = [
          { text: "More Info/Book", value: "moreInfo" },
          { text: "Destination", value: "destination" },
          { text: "Resort Name", value: "resortName" },
          { text: "Check In Date", value: "checkInDate" },
          { text: "Check Out Date", value: "checkOutDate" },
          { text: "# Nights", value: "numberOfNights" },
          { text: "Bedrooms", value: "bedrooms" },
          { text: "Max Occupancy", value: "maxOccupancy" },
          { text: "Kitchen", value: "kitchen" },
          { text: "Peak Season Surcharge", value: "peakSeasonSurchargeAmount" },
          {
            text: "High Occupancy Surcharge",
            value: "highOccupancySurchargeAmount",
          },
          { text: "Third Year Surcharge", value: "thirdYearSurcharge" },
        ];

        // garden unit upgrade surcharge
        if (allPropertyIds.length) {
          if (allPropertyIds.find((i) => i === "BDRC2")) {
            items.push({
              text: "Garden Unit Upgrade Surcharge",
              value: "gardenUnitUpgrade",
            });
          }
        }
        items.push({ text: "Total Surcharges", value: "totalSurcharges" });
        return items;
      } else {
        let items = [
          { text: "More Info/Book", value: "moreInfo" },
          { text: "Destination", value: "destination" },
          { text: "Resort Name", value: "resortName" },
          { text: "Check In Date", value: "checkInDate" },
          { text: "Check Out Date", value: "checkOutDate" },
          { text: "# Nights", value: "numberOfNights" },
          { text: "Bedrooms", value: "bedrooms" },
          { text: "Max Occupancy", value: "maxOccupancy" },
          { text: "Kitchen", value: "kitchen" },
          { text: "Peak Season Surcharge", value: "peakSeasonSurchargeAmount" },
          {
            text: "High Occupancy Surcharge",
            value: "highOccupancySurchargeAmount",
          },
        ];

        // garden unit upgrade surcharge
        if (allPropertyIds.length) {
          if (allPropertyIds.find((i) => i === "BDRC2")) {
            items.push({
              text: "Garden Unit Upgrade Surcharge",
              value: "gardenUnitUpgrade",
            });
          }
        }
        items.push({ text: "Total Surcharges", value: "totalSurcharges" });
        return items;
      }
    },
    userID() {
      return this.$store.state.userID;
    },
    loading() {
      return this.$store.state.loading;
    },
    filteredInventoryListings() {
      let items = this.items.sort((a, b) => {
        if (a.sortableCheckInDate === b.sortableCheckInDate) {
          return b.netProfit - a.netProfit;
        }
        return a.sortableCheckInDate - b.sortableCheckInDate;
      });
      return items.filter((i) => i.wouldDisplay).map(el=>{
        if(!this.unitSizeHotels.includes(el.resortPropertyId)) return el;

        if(!el.bedrooms) {
          let element = el;
          element.bedrooms = "Hotel"
          return element;
        }
        return el;
      });
    },
  },
  methods: {
    openPackageDialog() {
      this.packageDialog = true; // Change dialog state
    },
    closePackageDialog() {
      this.packageDialog = false; // Change dialog state
    },
    async onViewAccommodation(item) {
      item.packageCode = this.specificWinner.packageCode;
      await this.$store.dispatch("setSelectedInventoryListing", item);
      this.$router.push("/inventory/" + item.resortPropertyId);
    },
    async onSendMessage() {
      if (this.$refs.messageForm.validate()) {
        this.$store.dispatch("setLoading", true);
        this.message.from = this.userAccount.email;
        let docRef = firebase
          .firestore()
          .collection("userAccounts")
          .doc(this.userAccount.id);
        await docRef.update({
          timeline: firebase.firestore.FieldValue.arrayUnion(this.message),
          nodeMailer: { created: false, newMessage: false },
        });
        this.$refs.messageForm.reset();
        this.messageDialog = false;
        this.$store.dispatch("setLoading", false);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "success",
          text: "Message sent successfully",
          timeout: 5000,
        });
        return;
      }
      this.$store.dispatch("setLoading", false);
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "error",
        text: "Please check the form for errors",
        timeout: 5000,
      });
      return;
    },
    async constructInventoryItem(item) {
      let inventoryDocRef = firebase
        .firestore()
        .collection("inventoryProperties")
        .doc(item);

      let inventoryDoc = await inventoryDocRef.get();
      inventoryDoc = inventoryDoc.data();

      if (
        inventoryDoc.hasPeakSeasonSurcharge1 &&
        inventoryDoc.hasOwnProperty("peakSeasonSurcharge1Range") &&
        inventoryDoc.peakSeasonSurcharge1Range[1] <
          Moment().format("YYYY-MM-DD")
      ) {
        let range =
          Moment(inventoryDoc.peakSeasonSurcharge1Range[1]).format("YYYY") -
          Moment(inventoryDoc.peakSeasonSurcharge1Range[0]).format("YYYY");
        if (!range) {
          const isBefore =
            Moment(inventoryDoc.peakSeasonSurcharge1Range[1]).format("MM-DD") <
            Moment().format("MM-DD");

          if (isBefore) {
            inventoryDoc.peakSeasonSurcharge1Range = [
              Moment(inventoryDoc.peakSeasonSurcharge1Range[0])
                .year(Moment().format("YYYY") * 1 + 1)
                .format("YYYY-MM-DD"),
              Moment(inventoryDoc.peakSeasonSurcharge1Range[1])
                .year(Moment().format("YYYY") * 1 + 1)
                .format("YYYY-MM-DD"),
            ];
          } else {
            inventoryDoc.peakSeasonSurcharge1Range = [
              Moment(inventoryDoc.peakSeasonSurcharge1Range[0])
                .year(Moment().format("YYYY"))
                .format("YYYY-MM-DD"),
              Moment(inventoryDoc.peakSeasonSurcharge1Range[1])
                .year(Moment().format("YYYY"))
                .format("YYYY-MM-DD"),
            ];
          }
        } else {
          inventoryDoc.peakSeasonSurcharge1Range = [
            Moment(inventoryDoc.peakSeasonSurcharge1Range[0])
              .year(Moment().format("YYYY"))
              .format("YYYY-MM-DD"),
            Moment(inventoryDoc.peakSeasonSurcharge1Range[1])
              .year(Moment().format("YYYY") * 1 + range)
              .format("YYYY-MM-DD"),
          ];
        }
      }
      if (
        inventoryDoc.hasPeakSeasonSurcharge2 &&
        inventoryDoc.hasOwnProperty("peakSeasonSurcharge2Range") &&
        inventoryDoc.peakSeasonSurcharge2Range[1] <
          Moment().format("YYYY-MM-DD")
      ) {
        let range =
          Moment(inventoryDoc.peakSeasonSurcharge2Range[1]).format("YYYY") -
          Moment(inventoryDoc.peakSeasonSurcharge2Range[0]).format("YYYY");
        if (!range) {
          const isBefore =
            Moment(inventoryDoc.peakSeasonSurcharge2Range[1]).format("MM-DD") <
            Moment().format("MM-DD");

          if (isBefore) {
            inventoryDoc.peakSeasonSurcharge2Range = [
              Moment(inventoryDoc.peakSeasonSurcharge2Range[0])
                .year(Moment().format("YYYY") * 1 + 1)
                .format("YYYY-MM-DD"),
              Moment(inventoryDoc.peakSeasonSurcharge2Range[1])
                .year(Moment().format("YYYY") * 1 + 1)
                .format("YYYY-MM-DD"),
            ];
          } else {
            inventoryDoc.peakSeasonSurcharge2Range = [
              Moment(inventoryDoc.peakSeasonSurcharge2Range[0])
                .year(Moment().format("YYYY"))
                .format("YYYY-MM-DD"),
              Moment(inventoryDoc.peakSeasonSurcharge2Range[1])
                .year(Moment().format("YYYY"))
                .format("YYYY-MM-DD"),
            ];
          }
        } else {
          inventoryDoc.peakSeasonSurcharge2Range = [
            Moment(inventoryDoc.peakSeasonSurcharge2Range[0])
              .year(Moment().format("YYYY"))
              .format("YYYY-MM-DD"),
            Moment(inventoryDoc.peakSeasonSurcharge2Range[1])
              .year(Moment().format("YYYY") * 1 + range)
              .format("YYYY-MM-DD"),
          ];
        }
      }
      if (
        inventoryDoc.hasOwnProperty("peakSeasonSurcharge2Range") ||
        inventoryDoc.hasOwnProperty("peakSeasonSurcharge1Range")
      ) {
        await inventoryDocRef.update({
          peakSeasonSurcharge1Range: inventoryDoc.peakSeasonSurcharge1Range,
          peakSeasonSurcharge2Range: inventoryDoc.peakSeasonSurcharge2Range,
        });
      }

      // get inventory property
      let property = firebase
        .firestore()
        .collection("inventoryProperties")
        .doc(item);
      property = await property.get();
      property = property.data();

      // add costToOrg && bedrooms to property (filtering)
      property.costToOrg = this.winnerRetailPackage.costToOrg;
      property.retailBedrooms = this.winnerRetailPackage.bedrooms;

      // reduce to values needed for table
      delete property.archived;
      delete property.activities;
      delete property.airportInfo;
      delete property.amenities;
      delete property.checkInInfo;
      delete property.dateSubmitted;
      delete property.dateUpdated;
      delete property.destination;
      delete property.id;
      delete property["image-src"];
      delete property.otherInfo;
      delete property.overview;
      delete property.resortAddress;
      delete property.resortName;
      delete property.resortPropertyId;
      delete property.restaurants;
      delete property.restrictions;
      delete property.source;
      delete property.template;
      delete property.unitInfo;

      // get inventory items
      let listing = firebase
        .firestore()
        .collection("inventoryListings")
        .doc(item);
      listing = await listing.get();
      if (!listing.exists) return;

      let items = listing.data().items.filter((i) => i.archived == false);

      // add property values to listing item
      items.forEach((i) => {
        i.commissionsPaidPercentage = property.commissionsPaidPercentage;
        i.costOfExtras = property.costOfExtras;
        i.costToOrg = property.costToOrg;
        i.highOccupancySurchargeAmount = property.highOccupancySurchargeAmount;
        i.minimumProfitAccepted = property.minimumProfitAccepted;
        i.peakSeasonSurcharge1Range = property.peakSeasonSurcharge1Range;
        i.peakSeasonSurcharge2Range = property.peakSeasonSurcharge2Range;
        i.peakSeasonSurchargeAmount = property.peakSeasonSurchargeAmount;
        i.retailBedrooms = property.retailBedrooms;
        i.taxesFeesPercentage = property.taxesFeesPercentage;
      });

      // removing any items less than 90 days from today
      let twoWeeksFromToday = moment().startOf("day").add(90, "days");
      items = items.filter((i) => {
        let isBefore = moment(i.checkInDate).isBefore(twoWeeksFromToday);
        if (!isBefore) return i;
      });

      // removing any items beyond users travelByDate
      let cutoff = moment(this.specificWinner.travelByDate, "YYYY-MM-DD");
      items = items.filter((i) => {
        let checkInDate = moment(i.checkInDate, "YYYY-MM-DD");
        let isSameOrBefore = checkInDate.isSameOrBefore(cutoff);
        if (isSameOrBefore) return i;
      });

      // if 3rd year surcharge (event date is after 9/1/2020 && diff > 2 years)
      if (this.thirdYearSurcharge) {
        items.forEach((i) => {
          i.thirdYearSurcharge = 599;
        });
      }

      // format items
      items.forEach((i) => {
        // sortable checkInDate for table sorting
        i.sortableCheckInDate = i.checkInDate.replace(/-/g, "").trim();
        // create peakSeasonRanges for UI table display
        let peakSeasonRanges = [];
        i.peakSeasonSurcharge1Range.forEach((i) => peakSeasonRanges.push(i));
        i.peakSeasonSurcharge2Range.forEach((i) => peakSeasonRanges.push(i));
        i.peakSeasonRanges = peakSeasonRanges;
        // create moment peakSeason ranges
        let momentPeakSeason1Range = moment.range(
          i.peakSeasonSurcharge1Range[0],
          i.peakSeasonSurcharge1Range[1]
        );
        let momentPeakSeason2Range = moment.range(
          i.peakSeasonSurcharge2Range[0],
          i.peakSeasonSurcharge2Range[1]
        );
        // calc peakSeasonSurchargeAmount
        let originalPeakSeasonSurchargeAmount = i.peakSeasonSurchargeAmount;
        i.peakSeasonSurchargeAmount = 0;
        if (
          momentPeakSeason1Range.contains(moment(i.checkInDate)) ||
          momentPeakSeason2Range.contains(moment(i.checkInDate))
        ) {
          i.peakSeasonSurchargeAmount = originalPeakSeasonSurchargeAmount;
        }
        // highOccupancy surcharge calculation
        if (i.retailBedrooms >= i.bedrooms) i.highOccupancySurchargeAmount = 0;

        // hard coded exceptions for highOccupancySurchargeAmount (4d204)
        // if inventoryListings bedrooms greater than 2 (4d204 offers 1 or 2 bedrooms)
        if (this.winnerRetailPackage.packageCode == "4d204" && i.bedrooms > 2) {
          i.highOccupancySurchargeAmount =
            property.highOccupancySurchargeAmount;
        } else if (
          this.winnerRetailPackage.packageCode == "4d204" &&
          i.bedrooms <= 2
        ) {
          i.highOccupancySurchargeAmount = 0;
        }

        // adding 3rd year surcharge if applicable
        if (this.thirdYearSurcharge) {
          i.totalSurcharges =
            i.peakSeasonSurchargeAmount + i.highOccupancySurchargeAmount + 599;
        } else {
          i.totalSurcharges =
            i.peakSeasonSurchargeAmount + i.highOccupancySurchargeAmount;
        }

        // adding garden unit surcharge if applicable
        if (i.resortPropertyId === "BDRC2" && property?.gardenUnitUpgrade) {
          i.gardenUnitUpgrade = property.gardenUnitUpgrade;
        } else {
          i.gardenUnitUpgrade = 0;
        }

        // hard coded display changes
        // resortPropertyId D709 => changes bedroomsDisplay 'studio' to 'hotel'
        // resortPropertyId D709 => sets maxOccupancy to 2
        if (i.resortPropertyId === "D709" && i.bedroomsDisplay === "studio") {
          i.bedroomsDisplay = "hotel";
          i.maxOccupancy = 2;
        }

        i.totalSurcharges = i.totalSurcharges + i.gardenUnitUpgrade;

        i.totalIncome = i.costToOrg + i.totalSurcharges;
        i.commissionsPaid = (i.commissionsPaidPercentage / 100) * i.costToOrg;
        i.commissionsPaid = parseInt(i.commissionsPaid);
        i.taxesAndFees = (i.roomCost / 100) * i.taxesFeesPercentage;
        i.taxesAndFees = parseInt(i.taxesAndFees);
        i.totalCost = i.commissionsPaid + i.roomCost + i.taxesAndFees;
        i.netProfit = i.totalIncome - i.totalCost;
        let wouldDisplay = false;
        if (i.netProfit >= i.minimumProfitAccepted) wouldDisplay = true;
        i.wouldDisplay = wouldDisplay;

        this.items.push(i);
      });
      await this.$store.dispatch("setLoading", false);
    },
  },
  watch: {
    packageCode(newVal,oldVal){
     document.querySelector('.packageCode').remove();
      // document.querySelector('.maxicoPackage').remove();
    //  console.log("popup",popup)
    //  if(popup)  popup.remove();



        //Hawaii/Maui Wildfires Alert Dialoge

        //   let arrayOfAlertPackages = [
        //   "2d200",
        //   "4d100",
        //   "4d104",
        //   "4d200",
        //   "4d204",
        //   "sav236",
        //   "sav136",
        // ];

        // if (
        //   arrayOfAlertPackages.some(
        //     (el) => el == newVal
        //   )
        // )this.openPackageDialog()


        // Show aleart for 4D100, 4D200, SAV103, SAV104, 3D100, AI4IC, SAV118
        let maxicoPackage = [
          '4d100','4d200','sav103','sav104','3d100','ai4ic','sav118'
        ];
        if (
          maxicoPackage.some(
            (el) => el == newVal
          )
        )
          this.mexicoPackageDialog = true;
  },
    userID: {
      immediate: true,
      async handler(userID) {
        if (!userID) return this.$router.push("/");
        this.$store.state.loading = true;

        // bind userAccount
        let userAccount = await this.$bind(
          "userAccount",
          firebase.firestore().collection("userAccounts").doc(userID)
        );

        // route guards
        if (
          !userAccount.canBook ||
          !userAccount.canAccess ||
          !userAccount.canBrowseInventory
        )
          return this.$router.push("/dashboard");

        // get eventReport
        let eventReport = await firebase
          .firestore()
          .collection("eventReports")
          .doc(userAccount.eventReportID)
          .get();
        eventReport = eventReport.data();
        this.eventReport = eventReport;
        // get eventReport

        // get specificWinner in eventReport winners array
        let specificWinner = eventReport.winners.filter(
          (i) => i.packageID == userAccount.packageID
        )[0];
        this.specificWinner = specificWinner;


        this.alternativeDestinations  = this.alternativeDestinations.filter(
          (el)=> !specificWinner.destination.includes(el.matchPackage)
        )


       // if (specificWinner.costToOrg >= 1250)
        //   this.displayAlternativeDestinations = true;

        // displaying alternative destinations if applicable
        // let packageCodesToDisplayAlternativeDestinationsTo = [
        //   "sav136",
        //   "sav236",
        //   "4d100",
        //   "4d102",
        //   "4d104",
        //   "2d200",
        //   "4d200",
        //   "4d204",
        //   "ai4ic",
        //   "ir2bd",
        //   "ircmwt",
        //   "sav111",
        //   "sav211",
        //   "gce4na",
        //   "gce4wa",
        //   "vwfyna",
        //   "vwfywa",
        //   "cotvna",
        //   "cotvwa",
        //   "4luk1",
        //   "wahpna",
        //   "wahpwa",
        //   "rc7gbs",
        //   "lotina",
        //   "lotiwa",
        //   "wirna",
        //   "wirwa",
        //   "sav198",
        //   "sav120",
        //   "sav223",
        //   "sav202",
        //   "sav128",
        //   "sav227",
        //   "4d106",
        //   "nfh3",
        //   "sav219",
        //   "sav239",
        //   "3dmc3",
        //   "golf528",
        //   "sav137",
        //   "ultire",
        //   "3cmlv1",
        //   "ech3",
        //   "spski4",
        //   "ahg",
        //   "2d200wt",
        //   "golf900",
        //   "golf901",
        //   "sav270",
        //   "nap7",
        //   "cadmg",
        //   "cradv",
        //   "ech4",
        //   "fl3d",
        //   "husc",
        // ];

        // this.displayAlternativeDestinations =
        //   packageCodesToDisplayAlternativeDestinationsTo.some(
        //     (i) => i == this.specificWinner.packageCode
        //   );

        // displaying maui/kauai inventory info in importantInventoryInfo dialog

        // let packageCodesToDisplayMauiInfoTo = [
        //   "2d200wt",
        //   "2d200",
        //   "4d100",
        //   "4d104",
        //   "4d106",
        //   "4d200",
        //   "4d204",
        //   "golf904",
        //   "sav136",
        //   "sav236",
        // ];

        // show maui info in dialog if package code included in list above
        // disabled after all resorts store starting showing Maui inventory => 2/17/2022
        // this.showMauiInfoInImportantInfoDialog =
        //   packageCodesToDisplayMauiInfoTo.some(
        //     (i) => i == this.specificWinner.packageCode
        //   );

        // displaying alternative destinations to specific users
        // if (!this.displayAlternativeDestinations) {
        //   let userIDsToDisplayAlternativeDestionationsTo = [
        //     "H42NQHtyQ8gVKWkL3y9knhmh7Ov1",
        //   ];
        //   this.displayAlternativeDestinations =
        //     userIDsToDisplayAlternativeDestionationsTo.some((i) => i == userID);
        // }

        // get specificWinner retail package to list inventory options

        let retailPackage = firebase
          .firestore()
          .collection("retailPackages")
          .doc(specificWinner.packageCode);
        retailPackage = await retailPackage.get();
        retailPackage = retailPackage.data();
        this.winnerRetailPackage = retailPackage;
        // get specificWinner retail package to list inventory options

        // if no inventoryProperties assigned => stop page load
        if (!retailPackage.inventoryProperties.length)
          return await this.$store.dispatch("setLoading", false);

        // check is 3rd year surcharge is applicable to package
        let eventDate = moment(this.eventReport.eventDate, "YYYY-MM-DD");
        let cutoff = moment("2020-09-01", "YYYY-MM-DD");
        let isSameOrAfterCutoff = eventDate.isSameOrAfter(cutoff);

        // if package meets criteria for a potential 3rd year surcharge
        if (isSameOrAfterCutoff) {
          // check if package is in it's 3rd year
          let today = moment();
          // diff "true" param to include decimals (2.005, etc)
          let diff = today.diff(eventDate, "years", true);
          // if diff greater than 2 => package is in 3rd year => apply 3rd year surcharge
          if (diff > 2) this.thirdYearSurcharge = true;
        }

        this.packageCode = this.winnerRetailPackage.packageCode;

        // let arrayOfAlertPackages = [
        //   "2d200",
        //   "4d100",
        //   "4d104",
        //   "4d200",
        //   "4d204",
        //   "sav236",
        //   "sav136",
        // ];

        // if (
        //   arrayOfAlertPackages.some(
        //     (el) => el == this.winnerRetailPackage.packageCode
        //   )
        // )this.openPackageDialog()


        // // Show aleart for 4D100, 4D200, SAV103, SAV104, 3D100, AI4IC, SAV118
        // let maxicoPackage = [
        //   '4d100','4d200','sav103','sav104','3d100','ai4ic','sav118'
        // ];
        // if (
        //   maxicoPackage.some(
        //     (el) => el == this.winnerRetailPackage.packageCode
        //   )
        // )
        //   this.mexicoPackageDialog = true;


        //  sav103 => Your Options Have Been Expanded => dialog
        if (this.winnerRetailPackage.packageCode === "sav103")
          this.sav103Dialog = true;

        //  sav104 => Your Options Have Been Expanded => dialog
        if (this.winnerRetailPackage.packageCode === "sav104")
          this.sav104Dialog = true;

        // push each property to construction function
        retailPackage.inventoryProperties.forEach((i) => {
          this.constructInventoryItem(i);
        });
      },
    },
  },
};
</script>

<style>
tr {
  cursor: pointer;
  user-select: none !important;
}
td {
  height: 36px !important;
  width: 1px;
  white-space: nowrap;
  text-transform: capitalize;
}
</style>
